export const getUserAgent = ({ navigator }) => navigator?.userAgent

export const getUserIP = async () => await fetch('/.netlify/functions/get-user-ip').then((response) => response.text())

export const getUserLeadGeo = async ({ ip_address }) => {
  const response_json = await fetch('/.netlify/functions/get-location-data', {
    method: 'POST',
    body: JSON.stringify({ ip_address }),
  }).then((response) => response.json())

  return response_json?.region_code
}

export const getGoogleAnalyticsClientID = () =>
  typeof ga !== 'undefined' ? ga?.getAll?.()?.[0]?.get?.('clientId') : ''

export const getSegmentAnonymousID = () => analytics?.user?.()?.anonymousId?.()
