import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import ArrowLeftSVG from '@/assets/svg/arrow-left.svg'
import ChevronDownSVG from '@/assets/svg/chevron-down.svg'
import { getGoogleAnalyticsClientID } from '@/base/helpers/getUserData'
import { useLocationContext } from '@/base/context/LocationContext'
import { Image } from '@/components/elements/Image'

import { isExternalLink, isUtilityLink } from '@/base/helpers/link'

import * as styles from './styles.module.css'

const getTarget = ({ to = '/', link = {} }) => {
  if (link?.slug) {
    to = `/${link.slug}`
  }

  if (link?.url) {
    to = link.url
  }

  return to
}

const getGoogleAnalyticsURLParam = (location) => {
  const googleAnalyticsClientID = getGoogleAnalyticsClientID()

  if (!googleAnalyticsClientID) {
    return ''
  }

  const prefix = location?.search?.includes('?') ? '&' : '?'

  return `${prefix}google_analytics_client_id=${googleAnalyticsClientID}`
}

export const Link = ({ to, link, label, variant = 'link', icon, children, ...rest }) => {
  const { location, utm_params } = useLocationContext() || {}

  const title = label || (typeof children === 'string' && children) || undefined

  const [className, appearance] = variant.split('-')

  const props = {
    title,
    ...{ className: styles[className], variant: appearance, ...rest },
    ...rest,
  }

  const content = (
    <span>
      {icon && <Image image={icon} className={styles.icon} />}
      {variant === 'link-back' && <ArrowLeftSVG className={styles.icon} />}
      {label || children}
      {variant === 'link-arrow' && <ChevronDownSVG />}
    </span>
  )

  to = getTarget({ to, link })

  if (isUtilityLink(to)) {
    return <a {...{ href: to, target: '_self', ...props }}>{content}</a>
  }

  if (isExternalLink(to)) {
    // external page - append utm params if directly specified
    to += link?.withUtmParameters ? utm_params : ''

    // external page - append google analytics id if directly specified
    if (link?.withGoogleAnalyticsId) {
      const onClick = () => {
        to += getGoogleAnalyticsURLParam(location)
        window.location.href = to
      }

      return <a {...{ onClick, ...props }}>{content}</a>
    }

    return <a {...{ href: to, target: '_self', ...props }}>{content}</a>
  }

  // internal page - always append utm params
  to += utm_params || ''

  return <GatsbyLink {...{ to, ...props }}>{content}</GatsbyLink>
}
