import { useIsScrolled } from '@/base/hooks/useIsScrolled'
import { useToggle } from '@/base/hooks/useToggle'

export const useMenuState = () => {
  const isScrolled = useIsScrolled()

  const [isOpened, toggleIsOpened] = useToggle()

  const isActive = !isScrolled && !isOpened

  return { isActive, isOpened, toggleIsOpened }
}
