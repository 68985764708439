import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Image = ({ image, ...props }) => {
  // Gatsby Image
  if (image?.gatsbyImageData) {
    return <GatsbyImage image={image.gatsbyImageData} alt={image.alt} {...props} />
  }

  // SVG Image (using url)
  if (image?.file?.url) {
    return <img src={image.file.url} alt={image.alt} {...props} />
  }

  // SVG
  if (image?.SVG) {
    return <image.SVG {...props} />
  }

  // Default Image
  if (image?.src) {
    return <img src={image.src} {...props} />
  }

  return null
}
