import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { getLanguageConfigByLanguageCode } from '@/base/language/getLanguageConfig'

import { Link } from '@/components/elements/Link'

export const LinkLanguageToggle = (props) => {
  const { alternateLanguagePagePaths, language_code } = useLanguageContext()

  let alternateLanguagePageLanguageCode = 'es'

  if (language_code === 'es') {
    alternateLanguagePageLanguageCode = 'en'
  }

  const { language_name } = getLanguageConfigByLanguageCode(alternateLanguagePageLanguageCode)

  const alternateLanguagePagePath = alternateLanguagePagePaths[alternateLanguagePageLanguageCode]

  return (
    <Link to={alternateLanguagePagePath} {...props}>
      {language_name}
    </Link>
  )
}
