// extracted by mini-css-extract-plugin
export var placeholder = "styles-module--placeholder--6lv9q";
export var header = "styles-module--header--9vNDd";
export var caminoLogo = "styles-module--caminoLogo--oIOxw";
export var logos = "styles-module--logos--K6NvP";
export var partnerLogo = "styles-module--partnerLogo--EVLAW";
export var links = "styles-module--links--NCZfB";
export var items = "styles-module--items--KMumh";
export var hide = "styles-module--hide--FSf6w";
export var buttons = "styles-module--buttons--88UJp styles-module--hide--FSf6w";
export var cta = "styles-module--cta--lT1OC styles-module--hide--FSf6w";
export var linkLanguageToggle = "styles-module--linkLanguageToggle--Mxdgn";
export var hamburger = "styles-module--hamburger--E99aa";