import React from 'react'

import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'

import * as styles from './styles.module.css'

const ListItem = (props) => <Link variant="link-box" {...props} />

export const Dropdown = ({ link, links = [], ...rest }) => (
  <div className={styles.dropdown}>
    <Link {...link} {...rest} />
    <List items={links} component={ListItem} className={styles.links} style={{ '--links-length': links.length }} />
  </div>
)
