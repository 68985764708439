import React from 'react'

import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'

export const LinkDrawer = ({ link, links = [], ...rest }) => (
  <div>
    <Link {...link} {...rest} />
    <List items={links} component={Link} />
  </div>
)
