import React from 'react'

import SVG from '@/assets/svg/logo.svg'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { getCaminoFinancialHomePage } from '@/base/language/getPagePath'
import { useMenuState } from '@/base/hooks/useMenuState'
import { useIsScrolled } from '@/base/hooks/useIsScrolled'

import { Link } from '@/components/elements/Link'
import { Link as LinkVariant } from '@/components/elements/Link/getLinkFromVariant'
import { List } from '@/components/elements/List'
import { Image } from '@/components/elements/Image'
import { Hamburger } from '@/components/elements/Hamburger'
import { Drawer } from '@/components/modules/Drawer'
import { LinkLanguageToggle } from '@/components/modules/LinkLanguageToggle'
import { MenuItem } from '@/components/modules/MenuItem'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const HEADER_HEIGHT = 80
const LANGUAGE_TOGGLE_BAR_HEIGHT = 40

export const Header = ({
  partnerLogo,
  partnerLogoUrl,
  items,
  buttons,
  languageToggleVariant = 'link',
  theme = 'light',
  isTransparentOnTop,
}) => {
  const { language_code } = useLanguageContext()

  const isScrolled = useIsScrolled()

  const { isOpened, toggleIsOpened } = useMenuState()

  const homePath = getCaminoFinancialHomePage({ language_code })

  theme = isTransparentOnTop && !isScrolled ? 'dark' : theme

  const areLinksDisplayed = String(items?.length > 0)
  const isLanguageToggleBarVisible = languageToggleVariant === 'bar'
  const isLanguageToggleLinkVisible = languageToggleVariant === 'link'

  return (
    <div
      style={{
        '--header-height': `${HEADER_HEIGHT}px`,
        '--height-language-toggle': `${isLanguageToggleBarVisible ? LANGUAGE_TOGGLE_BAR_HEIGHT : 0}px`,
      }}
    >
      <div className={styles.placeholder} is-transparent-on-top={String(isTransparentOnTop)} />
      <header
        className={styles.header}
        theme={theme}
        is-transparent-on-top={String(isTransparentOnTop)}
        is-scrolled={String(isScrolled)}
        is-opened={String(isOpened)}
      >
        {isLanguageToggleBarVisible && (
          <Section with-background="true" with-padding="false">
            <div className={styles.linkLanguageToggle}>
              <LinkLanguageToggle variant="button" theme="secondary" />
            </div>
          </Section>
        )}

        <Section with-shadow="true" style={{ overflow: 'visible' }}>
          <div className={styles.logos}>
            <Link link={{ url: homePath, withUtmParameters: true }}>
              <SVG height="52" className={styles.caminoLogo} />
            </Link>
            <div className={styles.partnerLogo}>
              <Link link={partnerLogoUrl}>
                <Image image={partnerLogo} imgStyle={{ objectFit: 'contain' }} />
              </Link>
            </div>
          </div>

          <div className={styles.links}>
            <List items={items} component={MenuItem} className={styles.items} itemProps={{ theme }} />

            <List
              items={buttons}
              component={LinkVariant}
              className={styles.buttons}
              are-links-displayed={areLinksDisplayed}
              itemProps={{ theme, 'data-size': 'sm' }}
            />

            {isLanguageToggleLinkVisible && (
              <div className={styles.cta} are-links-displayed={areLinksDisplayed}>
                <LinkLanguageToggle variant="link-uppercase" theme={theme} />
              </div>
            )}
          </div>

          <div className={styles.hamburger} are-links-displayed={areLinksDisplayed}>
            <Hamburger onClick={toggleIsOpened} is-opened={String(isOpened)} />
          </div>
        </Section>
      </header>

      {areLinksDisplayed && <Drawer items={items} buttons={buttons} is-opened={String(isOpened)} />}
    </div>
  )
}
