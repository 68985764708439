import React from 'react'

import { Link as LinkElement } from '@/components/elements/Link'

const VARIANTS = {
  'white with a gray label and a border': {
    variant: 'button-border',
    border: 'gray',
  },
  'orange with a white label': {
    variant: 'button-default',
  },
  'white with an orange label': {
    variant: 'button-default',
    theme: 'white',
  },
  'transparent with a white label': {
    variant: 'button-border',
    theme: 'white',
  },
  'transparent with orange label and border': {
    variant: 'button-border',
    theme: 'primary',
  },
}

export const Link = ({ variant, ...props }) => <LinkElement {...props} {...VARIANTS[variant]} />
