const languageConstants = require('./constants.json')

const LANGUAGE_CODE_FALLBACK = 'en'

const getLanguageConstants = (language_code) =>
  languageConstants[language_code] || languageConstants[LANGUAGE_CODE_FALLBACK]

const getTranslateFunction =
  (language_code) =>
  ([path]) => {
    const constants = getLanguageConstants(language_code)

    const getFallback = () => {
      if (language_code === LANGUAGE_CODE_FALLBACK) {
        console.log(`translation for [${path}] does not exist`)
        return ''
      }

      return getTranslateFunction(LANGUAGE_CODE_FALLBACK)([path])
    }

    return path.split('.').reduce((acc, key) => acc[key] || getFallback(), constants)
  }

exports.getTranslateFunction = getTranslateFunction
