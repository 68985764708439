import React from 'react'

import { throttle } from '@/base/helpers/throttle'

export const useIsScrolled = () => {
  const HIDE_HEADER_THRESHOLD = 100

  const [isScrolled, setIsScrolled] = React.useState(false)

  const updateScrollPosition = () => setIsScrolled(window.pageYOffset > HIDE_HEADER_THRESHOLD)

  React.useEffect(() => {
    updateScrollPosition()

    const handleScroll = throttle(updateScrollPosition, 50)

    window.addEventListener('scroll', handleScroll)

    return () => {
      return window.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled])

  return isScrolled
}
