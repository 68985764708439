import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'

import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { Link as LinkVariant } from '@/components/elements/Link/getLinkFromVariant'
import { LinkLanguageToggle } from '@/components/modules/LinkLanguageToggle'
import { MenuItem } from '@/components/modules/MenuItem'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const ListItem = (props) => (
  <>
    <hr />
    <MenuItem isMobile={true} variant="link-bold" {...props} />
  </>
)

export const Drawer = ({ items, buttons, ...rest }) => {
  const { t } = useLanguageContext()

  const theme = 'dark'

  return (
    <div className={styles.drawer} {...rest}>
      <Section with-padding="true">
        <div>
          <LinkLanguageToggle variant="link-uppercase" theme={theme} />
        </div>
        <List items={items} component={ListItem} className={styles.items} />
        <hr />
        <List items={buttons} component={LinkVariant} className={styles.buttons} itemProps={{ theme }} />

        <div className={styles.helpMessage}>
          {t`help`}
          <Link to={`tel:${t`phone_nr`}`} label={t`phone_nr`} variant="link-bold" />
        </div>
      </Section>
    </div>
  )
}
