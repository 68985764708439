import React from 'react'

import { getTranslateFunction } from '@/base/language/getLanguageConstants'

const LanguageContext = React.createContext(null)

export const LanguageContextProvider = ({ alternateLanguagePagePaths, language_code, children }) => (
  <LanguageContext.Provider
    value={{
      alternateLanguagePagePaths,
      language_code,
      t: getTranslateFunction(language_code),
    }}
  >
    {children}
  </LanguageContext.Provider>
)

export const useLanguageContext = () => React.useContext(LanguageContext)
