import React from 'react'

const LocationContext = React.createContext(null)

export const LocationContextProvider = ({ location, children }) => {
  const search = location?.search

  const [utm_params, setUtmParams] = React.useState('')

  React.useEffect(() => {
    if (search?.includes('utm_')) {
      setUtmParams(search)
    }
  }, [search])

  return <LocationContext.Provider value={{ location, utm_params }}>{children}</LocationContext.Provider>
}

export const useLocationContext = () => React.useContext(LocationContext)
