import React from 'react'

import { ToggleViewMoreLess } from '@/components/modules/Toggle/ViewMoreLess'

export const useToggle = () => {
  const [isActive, setIsActive] = React.useState()

  const toggleIsActive = () => setIsActive(!isActive)

  const component = <ToggleViewMoreLess isActive={isActive} toggleIsActive={toggleIsActive} />

  return [isActive, toggleIsActive, component]
}
