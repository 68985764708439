const { removeRepeatingSlashes } = require('../helpers/regex.js')
const { getLanguageConfigByNodeLocale, getLanguageConfigByLanguageCode } = require('./getLanguageConfig.js')

exports.getPagePathByNodeLocale = ({ node_locale, slug }) => {
  const { language_code, path_prefix } = getLanguageConfigByNodeLocale(node_locale)

  return {
    language_code,
    path: removeRepeatingSlashes(`/${path_prefix}/${slug}/`),
  }
}

exports.getPagePathByLanguageCode = ({ language_code, slug }) => {
  const { path_prefix } = getLanguageConfigByLanguageCode(language_code)

  return removeRepeatingSlashes(`/${path_prefix}/${slug}/`)
}

exports.getCaminoFinancialHomePage = ({ language_code }) => {
  const { path_prefix } = getLanguageConfigByLanguageCode(language_code)

  return `https://www.caminofinancial.com/${path_prefix}`
}
