import React from 'react'
import uniqid from 'uniqid'

export const List = ({ items, component: Component, itemProps, ...listProps }) => {
  if ((items || []).length === 0) {
    return null
  }

  return (
    <ul {...listProps}>
      {items.map((item) => (
        <li key={item.id || uniqid()}>
          <Component {...item} {...itemProps} />
        </li>
      ))}
    </ul>
  )
}
