import React from 'react'

import { Link } from '@/components/elements/Link'
import { LinkDrawer } from '@/components/modules/LinkDrawer'
import { Dropdown } from '@/components/modules/Dropdown'

export const MenuItem = ({ internal: { type }, isMobile, ...props }) => {
  const Components = {
    ContentfulElementLink: Link,
    ContentfulElementDropdown: isMobile ? LinkDrawer : Dropdown,
  }

  const Component = Components[type]

  return <Component {...props} />
}
