import React from 'react'

import ChevronDownSVG from '@/assets/svg/chevron-down.svg'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { useToggle } from '@/base/hooks/useToggle'

import * as styles from './styles.module.css'

export const ToggleViewMoreLess = (props) => {
  const { t } = useLanguageContext()

  const [isActive, toggleIsActive] = useToggle(props?.isActive)

  const toggleActive = () => {
    toggleIsActive()
    props?.toggleIsActive?.()
  }

  return (
    <div className={styles.toggle}>
      <button onClick={toggleActive}>
        {isActive ? t`view_less` : t`view_more`}
        <ChevronDownSVG width="20" is-active={String(isActive)} />
      </button>
    </div>
  )
}
