import React from 'react'

import SquigglyLineLeftSVG from '@/assets/svg/squiggly-left.svg'
import SquigglyLineRightSVG from '@/assets/svg/squiggly-right.svg'

import * as styles from './styles.module.css'

export const Section = ({ children, className = '', ...rest }) => (
  <section {...{ className: [className, styles.section].join(' '), ...rest }}>
    <div>{children}</div>

    <span className={styles.decorators}>
      <SquigglyLineLeftSVG className={styles.left} />
      <SquigglyLineRightSVG className={styles.right} />
    </span>
  </section>
)
